import React from "react";
import styled from "styled-components";
import "../App.css";

import Landing from "../components/landing";
import Modal from "../components/modal"

const Container = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;

  align-items: center;
  justify-content: center;
  padding: 50;
`;

const Index = () => {
  return (
    <Container>
      {/* <Modal /> */}
      <Landing />
    </Container>
  );
};

export default Index;
